.login-header-wrapper {
  background-color: var(--cl1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  .error-text {
    color: #fff !important;
  }
  .login-form {
    display: flex;
  }
  .login-header {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .logo-wrapper {
    display: flex;
    img {
      width: 64px;
      height: 100%;
    }
    .link-news {
      margin-left: 1.5rem;
      display: grid;
      place-items: center;
      color: #fff;
      font-weight: 700;
      font-size: 14px;
      a {
        padding: 0.5rem;
      }
    }
    .link-news a:hover {
      color: #fff;
      font-weight: 700;
      font-size: 14px;
      background-color: rgba(255, 255, 255, 0.15);
      border-radius: 5px;
    }
  }
  .login-section.show {
    display: flex;
    flex-direction: column;
    .form-wrapper,
    .login-form {
      width: 100%;
    }
  }
  .login-section.hide {
    display: none;
  }
}

@media (max-width: 576.98px) {
  .login-header-wrapper {
    .logo-wrapper {
      .link-news {
        margin-left: 0;
        padding: 0.2rem;
        a {
          padding: 0.2rem;
        }
      }
    }
  }
}

@media (min-width: 880px) {
  .responsive-login-wrapper {
    display: none;
  }
  .login-section {
    display: grid !important;
    place-items: center !important;
  }
}

@media (max-width: 879.98px) {
  .login-header-wrapper {
    .login-header {
      flex-wrap: wrap;
      .logo-wrapper {
        flex: 1 1 80%;
        justify-content: flex-start;
      }
      .responsive-login-wrapper {
        flex: 1 1 20%;
        place-items: center;
        display: grid;
        place-items: center;
        .responsive-login-button {
          text-align: center;
          display: block;
          color: white;
          padding: 0.25rem;
          color: #fff;
          font-weight: 700;
          font-size: 14px;
          cursor: pointer;
        }
        .responsive-login-button:hover {
          color: #fff;
          font-weight: 700;
          font-size: 14px;
          background-color: rgba(255, 255, 255, 0.15);
          border-radius: 5px;
        }
      }
      .login-section {
        flex: 1 1 100%;
        width: 100%;
        .login-form {
          flex-direction: column;
        }
      }
    }
  }
}
